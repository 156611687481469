export const environment = {
    production: false,
    auth: {
        clientID: 'j94vEJZ2kFP261u3WNFFcfZ6zK7Dhf05',
        audience: 'https://media.ra.dev/rate-optics',
        domain: 'ra-media.auth0.com',
        algorithm: 'RSA256',
        responseType: 'token id_token',
        scope: 'openid profile email',
    },
    api: 'https://api.uat.rateoptics.io',
};
