import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LookupItem, SavedReport, StringLookupItem } from '@models/lookup';
import { BooleanFilter, MultiSelectFilter, SelectFilter } from '@models/filter-types';
import { LookupV2Service } from '@services/lookup-v2/lookup-v2.service';
import { startTimeFilter } from '@models/base_filters';
import * as moment from 'moment/moment';

const monday = 'Monday';
const nineAm = moment().startOf('day').hour(9).minute(0);

export const FREQUENCYINTERVALS = [
    { id: 1, name: 'Daily', displayName: 'Daily' },
    { id: 2, name: 'Weekly', displayName: 'Weekly' },
    { id: 3, name: 'Monthly', displayName: 'Monthly' },
    { id: 4, name: 'Quarterly', displayName: 'Quarterly' },
    { id: 0, name: 'One-Time', displayName: 'One-Time' },
];


// Do not change this values, these are used to map python now.weekday()
const daysOfWeek = [
    { id: 0, name: 'Monday', displayName: 'Monday' },
    { id: 1, name: 'Tuesday', displayName: 'Tuesday' },
    { id: 2, name: 'Wednesday', displayName: 'Wednesday' },
    { id: 3, name: 'Thursday', displayName: 'Thursday' },
    { id: 4, name: 'Friday', displayName: 'Friday' },
    { id: 5, name: 'Saturday', displayName: 'Saturday' },
    { id: 6, name: 'Sunday', displayName: 'Sunday' },
];

const weekOfMonth = [
    { id: '1', name: '1st', displayName: '1st' },
    { id: '2', name: '2nd', displayName: '2nd' },
    { id: '3', name: '3rd', displayName: '3rd' },
    { id: '4', name: '4th', displayName: '4th' },
    { id: 'L', name: 'Last', displayName: 'Last' },
];

@Component({
    selector: 'app-email-reports-pop-up',
    templateUrl: './email-reports-pop-up.html',
    styleUrls: ['./email-reports-pop-up.scss'],
})
export class EmailReportsPopUpComponent {
    isDaily = false;
    isWeekly = false;
    isMonthly = false;
    isQuarterly = false;
    isOneTime = false;
    report: SavedReport;

    frequencyFilter = new SelectFilter<LookupItem>('Frequency')
        .Options(FREQUENCYINTERVALS)
        .OnChange((item: LookupItem) => {
            if (!item) {
                return;
            }
            this.isDaily = item.id === 1;
            this.isWeekly = item.id === 2;
            this.isMonthly = item.id === 3;
            this.isQuarterly = item.id === 4;
            this.isOneTime = item.id === 0;
            if (this.isQuarterly) {
                this.dayOfWeekFilter.Value = [this.dayOfWeekFilter.options.find(option => option.name === monday)];
                const offset = new Date().getTimezoneOffset(); // Gets timezone in minutes
                this.timeFilter.Value = nineAm.utcOffset(offset);
            }
        });

    dayOfWeekFilter = new MultiSelectFilter<LookupItem>('Day of Week')
        .Options(daysOfWeek);

    weekOfMonth = new MultiSelectFilter<StringLookupItem>('Week Number')
        .Options(weekOfMonth);

    timeFilter = startTimeFilter('Time');

    firstDayOfTheMonth = new BooleanFilter('1st day of the month', false)
        .Slider(true);


    constructor(
        private lookupV2Service: LookupV2Service,
        private dialogRef: MatDialogRef<EmailReportsPopUpComponent>,
        @Inject(MAT_DIALOG_DATA) public data: {
            report: SavedReport;
        },
    ) {
        this.report = data.report;
        if (this.report?.emailReports) {
            const emailConfig = this.report.emailReports;
            if (emailConfig?.frequencyId) {
                this.frequencyFilter.Value = this.frequencyFilter.options.find(option => option.id === emailConfig.frequencyId);
            }
            if (emailConfig?.firstDayOfMonth) {
                this.firstDayOfTheMonth.Value = emailConfig.firstDayOfMonth;
            }
            if (emailConfig?.dow) {
                this.dayOfWeekFilter.Value = this.dayOfWeekFilter.options.filter(option => emailConfig.dow.includes(option.id));
            }
            if (emailConfig?.weekOfMonth) {
                this.weekOfMonth.Value = this.weekOfMonth.options.filter(option => emailConfig.weekOfMonth.includes(option.id));
            }
            if (emailConfig?.time) {
                this.timeFilter.Value = moment(emailConfig.time);
            }
        }
    }

    validateFilters() {
        const frequencyFilterSelected = this.frequencyFilter.Value;
        const timeFilterSelected = this.timeFilter.Value;
        const dowFilterSelected = this.dayOfWeekFilter.Value.length > 0;
        const weekOfMonthSelected = this.weekOfMonth.Value.length > 0;

        if (this.isDaily) {
            return timeFilterSelected;
        }
        if (this.isWeekly) {
            return dowFilterSelected && timeFilterSelected;
        }
        if (this.isMonthly) {
            if (this.firstDayOfTheMonth.Value) {
                return timeFilterSelected;
            }
            return weekOfMonthSelected && dowFilterSelected && timeFilterSelected;
        }
        return frequencyFilterSelected;
    }

    onSaveFilters() {
        const emailReportId = this.report.emailReports?.emailReportId;
        const reportId = this.report.reportId;
        const frequencyId = this.frequencyFilter.Value.id;
        const dows = this.dayOfWeekFilter.Value.map(dow => dow.id);
        const time = (this.timeFilter && this.timeFilter.Value) ? this.timeFilter.Value.format('YYYY-MM-DD HH:mm:ss Z') : null;
        const week = this.weekOfMonth.Value.map(w => w.name);
        const firstDayOfMonth = this.firstDayOfTheMonth.Value;
        const userIds = [this.report.userId];
        this.report.emailReports.emailReportId = emailReportId;
        this.report.emailReports.frequencyId = frequencyId;
        this.report.emailReports.dow = dows;
        this.report.emailReports.time = time;
        this.report.emailReports.weekOfMonth = week;
        this.report.emailReports.firstDayOfMonth = firstDayOfMonth;

        this.lookupV2Service.saveEmailReportFrequency(
            emailReportId, reportId, frequencyId, dows, time, week, firstDayOfMonth, userIds,
        )
            .subscribe(result => {
                this.dialogRef.close();
            }, err => {
                console.error(err);
            });

    }

    closeDialogTrue() {
        this.dialogRef.close(true);
    }

    closeDialogFalse() {
        this.dialogRef.close(false);
    }
}
